// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/banner4.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrap-top[data-v-35fa9124]{line-height:1.905vw}.wrap .wrap-top[data-v-35fa9124]{padding:5.655vw}.wrap .wrap-top h5[data-v-35fa9124]{font-size:1.429vw;font-weight:500;color:#565656;margin-bottom:1.786vw}.wrap .wrap-top p[data-v-35fa9124]{font-size:.952vw;font-weight:400;color:#333;line-height:1.5;margin:.298vw 0}.wrap .wrap-top .mt30[data-v-35fa9124]{margin-top:2.679vw}.has-dot[data-v-35fa9124]{position:relative;padding-left:1.31vw}.has-dot[data-v-35fa9124]:after{content:\"\";position:absolute;left:0;top:.417vw;width:.714vw;height:.714vw;background:#303030}.swiper-container[data-v-35fa9124]{height:47.619vw;width:100%}.swiper-container .swiper-wrapper .swiper-slide[data-v-35fa9124]{text-align:center;line-height:29.762vw;height:100%;width:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;background-size:114.286vw 29.762vw}", ""]);
// Exports
module.exports = exports;
