<template>
  <div class="yunvideo">
    <!-- 头部 -->
    <!-- 展商须知 -->
    <!-- 内容 -->
    <div class="wrap w">
      <div class="wrap-top" v-html="contentData">
        
      </div>
    </div>
  </div>
</template>

<script>
import { getCloudNoticeListData } from "@/api/index";

export default {
  components: {
  },
  data() {
    return {
      contentData:''
    };
  },
  mounted() {
    
  },
  created(){
    this.initDateContent();
  },
  methods: {
    initDateContent(){
      getCloudNoticeListData({key:'zsxz'}).then(res=>{
        this.contentData = res.data.value
      })
    }
  }
};
</script>

<style scoped  lang="scss">
.wrap-top{
  line-height: 32px;
}
.wrap {
  //  background: red;

  .wrap-top {
    padding: 95px;
    h5 {
      font-size: 24px;
      font-weight: 500;
      color: #565656;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 1.5;
      margin: 5px 0;
    }
    .mt30 {
      margin-top: 45px;
    }
  }
}
.has-dot {
  position: relative;
  padding-left: 22px;
}
.has-dot::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 7px;
  width: 12px;
  height: 12px;
  background: #303030;
}

.swiper-container {
  height: 800px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
</style>